<template>
  <div class="edit-container" v-loading="loading == 'loading'">
    <el-form label-width="130px" class="edit-form" :model="formData" :rules="formRules" ref="form">
      <el-form-item label="活动名称：" prop="activityName">
        <el-input  v-model="formData.activityName"></el-input>
      </el-form-item>

      <el-form-item label="封面图：" prop="thumb">
        <upload-image :cropWidth="230" :cropHeight="134" v-model="formData.thumb" class="thumb" ></upload-image>
        <div class="upload-tip">尺寸：<template v-if="columnId == 17">557*324</template><template v-else>354*206</template>，上传图片格式为jpg、png，图片大小不超过15M</div>
      </el-form-item>

      <el-form-item label="状态：" prop="activityStatus">
        <el-radio v-model="formData.activityStatus" label="OPEN" border >开启</el-radio>
        <el-radio v-model="formData.activityStatus" label="HIDDEN" border >隐藏</el-radio>
      </el-form-item>

      <el-form-item label="活动链接：" prop="activityLink">
        <el-input  v-model="formData.activityLink"></el-input>
      </el-form-item>

      <el-form-item label="地点：" prop="address">
        <el-input  v-model="formData.address"></el-input>
      </el-form-item>

      <el-form-item label="活动时间：" prop="activityTime">
        <el-date-picker
          v-model="formData.activityTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="报名截止时间：" prop="applyEndTime">
        <el-date-picker
          v-model="formData.applyEndTime"
          type="datetime"
          placeholder="请选择"
          value-format="timestamp">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="活动简介：" prop="activityIntro">
        <el-input
          type="textarea"
          v-model="formData.activityIntro">
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" :loading="loading == 'submit'" @click="handleSubmit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import UploadImage from '@/components/UploadImage'
import {getActivityDetail,createActivity,updateActivity} from '@/api/activity'
import { deepClone } from '@/utils/tool.js'

export default {
  components: {
    UploadImage
  },
  data() {
    return {
      columnId: this.$route.meta.columnId,
      activityId: this.$route.query.activityId,
      formData:{},
      loading: false,
      formRules: {
        activityName: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        thumb: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
        activityStatus: [{ required: true, message: '请选择状态', trigger: 'blur' }],
        activityLink: [{ required: true, message: '请输入链接地址', trigger: 'blur' }],
        address: [{ required: true, message: '请输入地点', trigger: 'blur' }],
        activityTime: [{ required: true, message: '请选择活动时间', trigger: 'blur' }],
        applyEndTime: [{ required: true, message: '请选择报名截止时间', trigger: 'blur' }],
        activityIntro: [{ required: true, message: '请输入活动简介', trigger: 'blur' }],
      }
    }
  },
  created() {
    if(this.activityId){
      this.getDetail()
      this.loading = 'loading'
    }
  },
  methods: {
    getDetail() {
       getActivityDetail({activityId:this.activityId}).then(res => {
        if(res.data.activityId) {
          this.formData = res.data
          this.formData.activityTime = []
          this.formData.activityTime.push(res.data.activityStartTime,res.data.activityEndTime)
          this.formData = {...this.formData}
        } else {
          this.activityId = null
        }
        this.loading = false
        
      })
    },
    handleSubmit() {
      this.$refs.form.validate(async valid => {
        if(valid) {
          this.loading = 'submit'
          this.formData.activityStartTime = this.formData.activityTime[0]
          this.formData.activityEndTime = this.formData.activityTime[1]

          try{
            let activityId
            if(this.activityId){  // 修改
              this.formData.activityId = this.activityId
              await updateActivity(this.formData)
              activityId = this.activityId
            } else{ // 新建
              this.formData.columnId = this.columnId
              await createActivity(this.formData).then(res => {
                activityId = res.data.result
              })
            }
            this.loading = false
            this.$message({
              message: '保存成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                const path = this.$route.matched.slice(this.$route.matched.length-2, this.$route.matched.length-1)[0].path
                this.$router.push(path+'/detail?activityId='+activityId)
              }
            })
          } catch {
            this.loading = false
          }
          
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .thumb .image-uploader{
  width:230px;
  height:134px;
  line-height:134px;
}
/deep/ .el-textarea__inner{
  height:100px;
}
</style>